<template>
  <div class="form">
    <section class="form-section">
      <p class="form-headline">ログイン情報</p>
      <FormRow :required="!isEdit">
        <template v-slot:label>ユーザー名</template>
        <template v-slot:content>
          <div class="form-content-row">
            <div v-if="isEdit" class="form-group">
              <div class="form-group-item">{{ form.nameSei }} {{ form.nameMei }}</div>
            </div>
            <div v-else class="form-group">
              <div class="form-group-item">
                <input
                  v-maxlength
                  maxlength="20"
                  class="form-control"
                  :class="{ 'is-error': errors.nameSei }"
                  type="text"
                  placeholder="姓"
                  v-space
                  name="nameSei"
                  v-model="form.nameSei"
                />
              </div>
              <div class="form-group-item">
                <input
                  v-maxlength
                  maxlength="20"
                  class="form-control"
                  :class="{ 'is-error': errors.nameMei }"
                  type="text"
                  v-space
                  placeholder="名"
                  name="nameMei"
                  v-model="form.nameMei"
                />
              </div>
            </div>
          </div>
        </template>
      </FormRow>
      <FormRow :required="!isEdit">
        <template v-slot:label>メールアドレス / ID</template>
        <template v-slot:content>
          <div class="form-content-row">
            <div v-if="isEdit" class="form-group">
              <div class="form-group-item">{{ form.email }}</div>
            </div>
            <div v-else class="row">
              <div class="col-6">
                <input
                  v-trim
                  v-maxlength
                  maxlength="50"
                  class="form-control"
                  :class="{ 'is-error': errors.email }"
                  type="text"
                  v-model="form.email"
                  v-email
                  name="email"
                />
              </div>
            </div>
          </div>
        </template>
      </FormRow>
    </section>
    <section class="form-section">
      <p class="form-headline">企業情報</p>
      <FormRow v-if="!hasDirectory">
        <template v-slot:label>グループ</template>
        <template v-slot:content>
          <div class="form-content-row">
            <p>グループにまとめた店舗の情報は、グループのアカウントからも確認できるようになります。</p>
          </div>
          <div class="form-content-row">
            <div class="form-select">
              <select
                class="form-control form-select-input"
                :class="{ 'is-error': errors.shopGroupId }"
                name="shopGroupId"
                v-model="form.shopGroupId"
              >
                <option value="" selected>選択してください</option>
                <option
                  v-for="item in groupList"
                  :key="item.id"
                  :value="item.id"
                >{{ item.name }}</option>
              </select>
            </div>
          </div>
        </template>
      </FormRow>
      <FormRow :required="true">
        <template v-slot:label>企業名</template>
        <template v-slot:labelNote>（1文字以上45文字以内）</template>
        <template v-slot:content>
          <div class="form-content-row">
            <input
              v-trim
              v-maxlength
              maxlength="45"
              class="form-control"
              :class="{ 'is-error': errors.company }"
              type="text"
              name="company"
              v-model="form.company"
            />
          </div>
        </template>
      </FormRow>
    </section>
    <section class="form-section">
      <p class="form-headline">店舗情報</p>
      <FormRow :required="true">
        <template v-slot:label>店舗名</template>
        <template v-slot:labelNote>（1文字以上45文字以内）</template>
        <template v-slot:content>
          <div class="form-content-row">
            <input
              class="form-control"
              :class="{ 'is-error': errors.name }"
              type="text"
              name="name"
              v-model="form.name"
              v-trim
              v-maxlength
              maxlength="45"
            />
          </div>
        </template>
      </FormRow>
      <FormRow :required="true">
        <template v-slot:label>郵便番号</template>
        <template v-slot:content>
          <div class="form-content-row">
            <div class="row">
              <div class="col-3">
                <input
                  v-number
                  v-maxlength
                  maxlength="7"
                  class="form-control"
                  :class="{ 'is-error': errors.zipcode }"
                  type="tel"
                  name="zipcode"
                  v-model="form.zipcode"
                  @input="handleInput($event)"
                />
              </div>
            </div>
          </div>
        </template>
      </FormRow>
      <FormRow :required="true">
        <template v-slot:label>都道府県</template>
        <template v-slot:content>
          <div class="form-content-row">
            <div class="row">
              <div class="col-6">
                <div class="form-select">
                  <select
                    class="form-control form-select-input"
                    :class="{ 'is-error': errors.prefectureId }"
                    name="prefectureId"
                    v-model="form.prefectureId"
                    @change="getLocation"
                  >
                    <option value="" selected>選択してください</option>
                    <option
                      v-for="item in prefectures"
                      :key="item.id"
                      :value="item.id"
                    >{{ item.name }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </template>
      </FormRow>
      <FormRow :required="true">
        <template v-slot:label>住所</template>
        <template v-slot:content>
          <div class="form-content-row">
            <div class="form-select">
              <select
                class="form-control form-select-input"
                :class="{ 'is-error': errors.municipalityId }"
                name="municipalityId"
                v-model="form.municipalityId"
                @change="getLocation"
              >
                <option value="" selected>市区町村</option>
                <option
                  v-for="item in municipalities"
                  :key="item.id"
                  :value="item.id"
                >{{ item.name }}</option>
              </select>
            </div>
          </div>
          <div class="form-content-row">
            <input
              v-trim
              v-maxlength
              maxlength="40"
              class="form-control"
              :class="{ 'is-error': errors.streetAddress }"
              type="text"
              name="streetAddress"
              placeholder="丁番地"
              v-model="form.streetAddress"
              @change="getLocation"
            />
          </div>
          <div class="form-content-row">
            <input
              v-trim
              v-maxlength
              maxlength="40"
              class="form-control"
              :class="{ 'is-error': errors.buildingName }"
              type="text"
              name="buildingName"
              placeholder="建物名・号室"
              v-model="form.buildingName"
              @change="getLocation"
            />
          </div>
        </template>
      </FormRow>
      <FormRow>
        <template v-slot:label>地図</template>
        <template v-slot:content>
          <div class="form-content-row">
            <button class="btn btn-sm btn-black" type="button" @click="getLocation">住所から位置情報を取得する</button>
          </div>
          <div :class="{ 'form-content-row': true, 'is-error': errors.zipcode }">
            <div id="map" style="width: 100%; height: 320px;"></div>
          </div>
        </template>
      </FormRow>
      <FormRow :required="true">
        <template v-slot:label>電話番号</template>
        <template v-slot:content>
          <div class="form-content-row">
            <div class="row">
              <div class="col-3">
                <input
                  v-number
                  v-maxlength
                  maxlength="11"
                  class="form-control"
                  :class="{ 'is-error': errors.telephone }"
                  type="tel"
                  name="telephone"
                  v-model="form.telephone"
                />
              </div>
            </div>
          </div>
        </template>
      </FormRow>
      <FormRow :required="true">
        <template v-slot:label>ジャンル</template>
        <template v-slot:content>
          <div class="form-content-row">
            <div class="form-select">
              <select
                class="form-control form-select-input"
                :class="{ 'is-error': errors.genre }"
                name="genre"
                v-model="form.genre"
              >
                <option value="">選択してください</option>
                <option
                  v-for="item in genres"
                  :key="item.id"
                  :value="item.id"
                >{{ item.name }}</option>
              </select>
            </div>
          </div>
        </template>
      </FormRow>
      <FormRow>
        <template v-slot:label>カテゴリー</template>
        <template v-slot:content>
          <div class="form-content-row">
            <div class="form-select">
              <SearchSelect
                singleLabel="name"
                :isError="errors.categories"
                :getLabel="getCategoryLabel"
                :options="eventCategories"
                :initialValue="isEdit ? shopDetail.categories : []"
                @change-selection="handleSelectedCategories"
              />
            </div>
          </div>
        </template>
      </FormRow>
    </section>
    <section class="form-section">
      <p class="form-headline">口座情報</p>
      <p class="form-content-row form-content-link">ゆうちょ銀行の口座を入力する場合は銀行コード9900と、<a class="link" :href="registShopUrl" target="_blank">他の金融機関口座への送金に使用する店番・口座番号</a>をご入力ください。</p>
      <FormRow :required="true">
        <template v-slot:label>金融機関名</template>
        <template v-slot:content>
          <div class="form-content-row">
            <input
              v-trim
              v-maxlength
              maxlength="20"
              class="form-control"
              :class="{ 'is-error': errors.bank.bankName }"
              type="text"
              name="bankName"
              placeholder="金融機関名"
              v-model="form.bank.bankName"
            />
          </div>
          <div class="form-content-row">
            <div class="row">
              <div class="col-3">
                <input
                  v-number
                  v-maxlength
                  maxlength="4"
                  class="form-control"
                  :class="{ 'is-error': errors.bank.bankCode }"
                  type="tel"
                  name="bankCode"
                  placeholder="金融機関コード"
                  v-model="form.bank.bankCode"
                />
              </div>
            </div>
          </div>
        </template>
      </FormRow>
      <FormRow :required="true">
        <template v-slot:label>支店名</template>
        <template v-slot:content>
          <div class="form-content-row">
            <input
              v-trim
              v-maxlength
              maxlength="20"
              class="form-control"
              :class="{ 'is-error': errors.bank.branchName }"
              type="text"
              name="branchName"
              placeholder="支店名"
              v-model="form.bank.branchName"
            />
          </div>
          <div class="form-content-row">
            <div class="row">
              <div class="col-3">
                <input
                  v-number
                  v-maxlength
                  maxlength="3"
                  class="form-control"
                  :class="{ 'is-error': errors.bank.branchCode }"
                  type="tel"
                  name="branchCode"
                  placeholder="支店コード"
                  v-model="form.bank.branchCode"
                />
              </div>
            </div>
          </div>
        </template>
      </FormRow>
      <FormRow :required="true">
        <template v-slot:label>口座番号</template>
        <template v-slot:content>
          <div class="form-content-row">
            <div class="form-group">
              <div class="form-group-item">
                <div class="form-select">
                  <select
                    class="form-control form-select-input"
                    :class="{ 'is-error': errors.bank.type }"
                    name="type"
                    v-model="form.bank.type"
                  >
                    <option value="">選択してください</option>
                    <option v-for="item in bankTypes" :key="item.index" :value="item.value">{{ item.label }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group-item">
                <input
                  v-number
                  v-maxlength
                  maxlength="7"
                  class="form-control"
                  :class="{ 'is-error': errors.bank.number }"
                  type="tel"
                  name="number"
                  v-model="form.bank.number"
                />
              </div>
            </div>
          </div>
        </template>
      </FormRow>
      <FormRow :required="true">
        <template v-slot:label>口座名義</template>
        <template v-slot:labelNote>（カタカナ）</template>
        <template v-slot:content>
          <div class="form-content-row">
            <div class="row">
              <div class="col-12">
                <input
                  v-trim
                  v-maxlength
                  v-katakana
                  v-alphabet
                  maxlength="30"
                  class="form-control"
                  :class="{ 'is-error': errors.bank.name }"
                  type="text"
                  name="name"
                  v-model="form.bank.name"
                />
              </div>
            </div>
          </div>
        </template>
      </FormRow>
    </section>
    <section v-if="isChildEventExist" class="form-section">
      <p class="form-headline">利用するイベント</p>
      <FormRow>
        <template v-slot:content>
          <div class="form-content-row">
            加盟店として利用するイベントを選択してください
          </div>
        </template>
      </FormRow>
      <FormRow v-for="(event, index) in childEventData" :key="index">
        <template v-slot:label>{{ event.name }}</template>
        <template v-slot:toggle>
          <label class="form-toggle">
            <input
              class="form-toggle-input"
              type="checkbox"
              @change="handleSelectedEvent(index, event.directory)"
              v-model="form.events[index]"
              :disabled="form.events[index] && !allowChildUser"
            />
            <span class="form-toggle-set">
              <span class="form-toggle-bg"></span>
              <span class="form-toggle-circle"></span>
            </span>
          </label>
        </template>
        <template v-slot:content>
          <div v-if="form.events[index]" class="form-content-row">
            <ul class="listGroup">
              <li v-if="event.groups && event.groups.length" class="listGroup-item">
                <div class="form-content-row">
                  この店舗の属するイベントグループを選択してください
                </div>
                <ul class="listGrid">
                  <li v-for="group in event.groups" :key="group.index" class="listGrid-item">
                    <label class="form-radio form-radio-btn">
                      <input
                        class="form-radio-input"
                        type="radio"
                        :name="`group${index}`"
                        :value="group.id"
                        v-model="form.events[index].group"
                      />
                      <span :class="{
                        'form-radio-label': true,
                        'is-error': allowChildUser ? errors[`group${index}`] : errors.events.group
                      }">
                        {{ group.name }}
                      </span>
                    </label>
                  </li>
                </ul>
              </li>
              <li class="listGroup-item">
                <div class="form-content-row">
                  支払いサイクルを選択してください
                </div>
                <ul class="listGrid">
                  <li v-for="paymentCycle in event.paymentCycles" :key="paymentCycle.index" class="listGrid-item">
                    <label class="form-radio form-radio-btn">
                      <input
                        class="form-radio-input"
                        type="radio"
                        :name="`paymentCycle${index}`"
                        :value="paymentCycle.id"
                        v-model="form.events[index].paymentCycle"
                      />
                      <span :class="{
                        'form-radio-label': true,
                        'is-error': allowChildUser ? errors[`paymentCycle${index}`] : errors.events.paymentCycle
                      }">
                        {{ paymentCycle.name }}
                      </span>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </template>
      </FormRow>
    </section>
    <section class="form-section">
      <p class="form-headline" v-if="isUseCpmParentEvent">権限設定</p>
      <div class="form-row">
        <p>
          イベント画面の「使えるお店を探す」に表示しない場合にはボタンをOFFにしてください
        </p>
        <FormRow>
          <template v-slot:label>店舗検索に含める</template>
          <template v-slot:toggle>
            <label class="form-toggle">
              <input
                class="form-toggle-input"
                type="checkbox"
                name="searchShop"
                :checked="form.isSearchedFlag"
                v-model="form.isSearchedFlag"
              />
              <span class="form-toggle-set">
                <span class="form-toggle-bg"></span>
                <span class="form-toggle-circle"></span>
              </span>
            </label>
          </template>
        </FormRow>
      </div>
      <div class="form-row" v-if="isUseCpmParentEvent">
        <p>
          CPMカードにて購入（付与）の受付ができるようにするにはONにしてください
        </p>
        <FormRow>
          <template v-slot:label>CPMカード付与権限</template>
          <template v-slot:toggle>
            <label class="form-toggle">
              <input
                class="form-toggle-input"
                type="checkbox"
                name="searchShop"
                :checked="form.cpmFlag"
                v-model="form.cpmFlag"
              />
              <span class="form-toggle-set">
                <span class="form-toggle-bg"></span>
                <span class="form-toggle-circle"></span>
              </span>
            </label>
          </template>
        </FormRow>
      </div>
      <div class="form-row" v-if="isShowIssueCoupon">
        <p>クーポンの発行をできない店舗にする場合にはボタンをOFFにしてください</p>
        <p>加盟店に対する設定にて、クーポンがOFFの場合はこちらがONでも発行できません</p>
        <p class="text-bold">※こちらをOFFにした場合でも、管理画面側からの発行は可能です</p>
        <FormRow>
          <template v-slot:label>クーポン発行権限</template>
          <template v-slot:toggle>
            <label class="form-toggle">
              <input
                class="form-toggle-input"
                type="checkbox"
                name="searchShop"
                :checked="form.issueCoupon"
                v-model="form.issueCoupon"
              />
              <span class="form-toggle-set">
                <span class="form-toggle-bg"></span>
                <span class="form-toggle-circle"></span>
              </span>
            </label>
          </template>
        </FormRow>
      </div>
      <FormRow>
        <template v-slot:label>備考</template>
        <template v-slot:labelNote>（500文字以内）</template>
        <template v-slot:content>
          <div class="form-content-row">
            <textarea
              class="form-control form-textarea"
              :class="{ 'is-error': errors.remarks }"
              v-model="form.remarks"
              v-trim
              v-maxlength
              maxlength="500"
            ></textarea>
          </div>
        </template>
      </FormRow>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, requiredIf, maxLength, minLength, email } from 'vuelidate/lib/validators';
//helpers
import { isPhoneNumber, isBankName, notSpace, isZipcode, isBankCode, isBranchCode, isBankNumber } from '@/helpers/validators';
//mixins
import error from '@/mixins/plugin/error';
import { address } from '@/mixins/plugin/address';
import googleMap from '@/mixins/module/googleMap';
//components
import FormRow from '@/components/FormRow.vue';
import SearchSelect from '@/components/SearchSelect.vue';
//contants
import { StatusConstants } from '@/constants/status';
import { CommonConstants } from '@/constants/common';

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    allowChildUser: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      form: {
        nameSei: '',
        nameMei: '',
        shopGroupId: '',
        email: '',
        company: '',
        zipcode: '',
        prefectureId: '',
        municipalityId: '',
        streetAddress: '',
        buildingName: '',
        telephone: '',
        name: '',
        genre: '',
        categories: [],
        bank: {
          bankCode: '',
          bankName: '',
          branchCode: '',
          branchName: '',
          type: '',
          number: '',
          name: '',
        },
        events: [],
        latLng: CommonConstants.DEFAULT_LAT_LNG,
        isSearchedFlag: true,
        cpmFlag: false,
        issueCoupon: true,
      },
      prefixs: ['bank', 'events'],
      bankTypes: Object.values(StatusConstants.bank),
      registShopUrl: CommonConstants.REGIST_SHOP_URL,
    }
  },
  validations() {
    let form = {
      nameSei: { required, maxLength: maxLength(20), notSpace },
      nameMei: { required, maxLength: maxLength(20), notSpace },
      email: { required, email, maxLength: maxLength(50) },
      company: { required, minLength: minLength(1), maxLength: maxLength(45) },
      name: { required, minLength: minLength(1), maxLength: maxLength(45) },
      zipcode: { required, isZipcode },
      prefectureId: { selectRequired: required },
      municipalityId: { selectRequired: required },
      streetAddress: { required, maxLength: maxLength(40) },
      buildingName: { maxLength: maxLength(40) },
      telephone: { required, isPhoneNumber },
      genre: { selectRequired: required },
      bank: {
        bankName: { required, maxLength: maxLength(20) },
        bankCode: { required, isBankCode },
        branchName: { required, maxLength: maxLength(20) },
        branchCode: { required, isBranchCode },
        type: { selectRequired: required },
        number: { required, isBankNumber },
        name: { required, minLength: minLength(1), maxLength: maxLength(30), isBankName }
      },
      remarks: { maxLength: maxLength(500) },
      events: {
        $each: {
          group: {
            selectRequired: requiredIf(function(eventItem) {
              const isEventHadGroup = this.childEventData.find(item => item.directory === eventItem.directory)?.groups?.length;
              return (eventItem && isEventHadGroup) ? eventItem.directory : false;
            }),
          },
          paymentCycle: {
            selectRequired: requiredIf(function(eventItem) {
              return eventItem ? eventItem.directory : false;
            }),
          },
        },
      },
    }
    return { form };
  },
  mixins: [error, googleMap, address({ formName: 'form' })],
  components: {
    FormRow,
    SearchSelect,
  },
  mounted () {
    const loading = this.$loading.show();
    Promise.all([
      this.$store.dispatch('common/getGenres'),
      this.$store.dispatch('common/getPaymentCycles'),
      this.$store.dispatch('group/getGroupList', { limit: 9999, ...(!this.isClientChild && { allowChildUser: 1 }) }),
      this.$store.dispatch('event/getChildEventList', { includeUnregistered: 0, includeUnmanageable: 1, limit: 20 }),
      this.$store.dispatch('event/getParentEventDetail', this.$route.params.subdomain),
      this.getDetail(),
    ]).finally(() => {
      if (this.isEdit) {
        this.convertForm();
        if (!this.shopDetail.latLng?.latitude) this.getLocation();
      }
      this.convertChildEvents();
      this.$loading.clear(loading);
    })
  },
  computed: {
    ...mapGetters({
      genres: 'common/genres',
      shopDetail: 'shop/shopDetail',
      eventCategories: 'event/eventCategories',
      groupList: 'group/groupList',
      childEventList: 'event/childEventList',
      isClientChild: 'auth/isClientChild',
      isUseCpmParentEvent: 'event/isUseCpmParentEvent',
      childEventDetail: 'event/childEventDetail',
      isUseCouponParentEvent: 'event/isUseCouponParentEvent',
    }),
    shopId() {
      return this.$route.params.id;
    },
    hasDirectory() {
      return this.$route.params.directory;
    },
    address() {
      return `〒${this.form.zipcode}${this.form.prefectureName}${this.form.municipalityName}${this.form.streetAddress || ''}${this.form.buildingName || ''}`;
    },
    isChildEventExist() {
      return this.hasDirectory ? !!this.childEventDetail : this.childEventList?.length;
    },
    childEventData() {
      return this.hasDirectory ? [this.childEventDetail] : this.childEventList;
    },
    isShowIssueCoupon() {
      return this.isUseCouponParentEvent && !this.isClientChild && !this.hasDirectory;
    },
  },
  watch: {
    'form.prefectureId'(val) {
      this.getMunicipalities(val, this.form.municipalityId);
      this.form.prefectureName = (this.prefectures.find(item => item.id == val) || {}).name || '';
    },
    'form.municipalityId'(val) {
      if (val) {
        this.form.municipalityName = (this.municipalities.find(item => item.id == val) || []).name || '';
      }
    },
  },
  methods: {
    handleSelectedEvent(index, directory) {
      if (!this.allowChildUser) {
        this.form.events[index] = { directory };
        this.$message.showError('notOffToggleEvent');
      } else {
        if (this.form.events[index]) {
          this.form.events[index] = { directory };
        } else {
          this.form.events[index] = false;
          this.errors[`paymentCycle${index}`] = false;
          this.errors[`group${index}`] = false;
        }
      }
    },
    convertForm() {
      this.form = {
        ...this.shopDetail,
        email: this.shopDetail?.users?.[0] ? this.shopDetail.users[0].email : '',
        nameMei: this.shopDetail?.users?.[0] ? this.shopDetail.users[0].nameMei : '',
        nameSei: this.shopDetail?.users?.[0] ? this.shopDetail.users[0].nameSei : '',
        bank: this.shopDetail.bank || {},
        shopGroupId: this.shopDetail.shopGroup?.id || '',
        categories: this.shopDetail.categories || [],
        latLng: {
          lat: this.shopDetail.latLng?.latitude || 35.6762,
          lng: this.shopDetail.latLng?.longitude || 139.6503,
        },
      }
    },
    convertChildEvents() {
      this.form.events = this.childEventData.map(childEvent => {
        const selectedEvent = this.shopDetail?.events?.find(shopEvent => childEvent.directory === shopEvent.directory);
        if (!this.allowChildUser) {
          return { 'directory': childEvent.directory };
        } else {
          return selectedEvent ? {
            ...selectedEvent,
            group: selectedEvent.group?.id || null,
            paymentCycle: selectedEvent.paymentCycle?.id || null,
          } : false;
        }
      });
    },
    getCategoryLabel({ name }) {
      return name;
    },
    handleSelectedCategories(ids) {
      this.form.categories = ids;
    },
    formatData() {
      if (!this.isUseCouponParentEvent) this.form.issueCoupon = 0;
      return {
        ...this.form,
        events: this.form.events.filter(item => item),
        latLng: {
          latitude: this.form.latLng.lat,
          longitude: this.form.latLng.lng,
        },
      }
    },
    validate() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.getErrors(this.$v.form, 'shop', !this.allowChildUser);
        return false;
      } else {
        return this.formatData();
      }
    },
    async getDetail() {
      if (this.isEdit) {
        await this.$store.dispatch('shop/getShopDetail', { id: this.shopId });
      }
    },
  },
  beforeDestroy() {
    if (this.isEdit)
      this.$store.commit('shop/SET_SHOP_DETAIL', {});
  },
}
</script>

<style scoped>
.form-content-link {
  margin-bottom: 15px !important;
}
</style>
